import React from 'react';
import {FlashCard} from 'components/organisms';

const FlashCardShortcode = ({children, ...rest}) => {
  if (!children) {
    return <FlashCard {...rest} />;
  }
  const cs = React.Children.toArray(children);
  const prompt = cs.find(node => node.props.mdxType === 'Prompt').props.children;
  const answer = cs.find(node => node.props.mdxType === 'Answer').props.children;
  const selfcheck = cs.find(node => node.props.mdxType === 'SelfCheck');
  return <FlashCard {...{prompt, answer, selfcheck}} />;
};

export default FlashCardShortcode;
