import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  dialogImage: {
    maxWidth: '100%',      // Ensures the image is not wider than the screen
    maxHeight: '90vh', 
  },
  dBlock: {
    display: 'block',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.divider,
  },
}));

/**
 * Component to display the images
 *
 * @param {Object} props
 */
const Image = props => {
  const {src, srcSet, alt, lazy, lazyProps, className, ...rest} = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleOpen = () => {
    // Store the current scroll position before opening the dialog
    setScrollPosition(window.scrollY);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Restore the scroll position after the dialog closes
    const maxScroll = document.documentElement.scrollHeight - window.innerHeight - 1;
    window.scrollTo(0, Math.min(scrollPosition, maxScroll));
  };

  const renderImage = () => (
    <img
    className={clsx('image', classes.root, className )}
    alt={alt}
      src={src}
      srcSet={srcSet}
      {...rest}
    />
  );

  const renderDialogImage = () => (
    <img
    className={clsx(classes.dialogImage, className )}
    alt={alt}
      src={src}
      srcSet={srcSet}
    />
  );

  return (
    <div>
      <div onClick={handleOpen}>
        {lazy ? (
          <LazyLoadImage
            className={clsx('image', classes.root, classes.dBlock, className)}
            alt={alt}
            src={src}
            srcSet={srcSet}
            effect="opacity"
            {...lazyProps}
            {...rest}
          />
        ) : (
          renderImage()
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
      >
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        {renderDialogImage()}
      </Dialog>
    </div>
  );
};

Image.defaultProps = {
  alt: '...',
  lazy: true,
  lazyProps: {
    width: 'auto',
    height: 'auto',
  },
};

Image.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Source of the image
   */
  src: PropTypes.string.isRequired,
  /**
   * Source set for the responsive images
   */
  srcSet: PropTypes.string,
  /**
   * Image title
   */
  alt: PropTypes.string,
  /**
   * Lazy loading properties
   */
  lazyProps: PropTypes.object,
  /**
   * Should lazy load the image
   */
  lazy: PropTypes.bool,
};

export default Image;
