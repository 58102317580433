import {createMuiTheme, responsiveFontSizes} from '@material-ui/core';
import {light, dark} from './palette';

const getTheme = mode => {
  const theme = responsiveFontSizes(
    createMuiTheme({
      palette: mode === 'light' ? light : dark,
      layout: {
        contentWidth: 1236,
      },
      typography: {
        fontFamily: ['Jost', 'sans-serif'].join(','),
        lineHeight: 1.5,
      },
      zIndex: {
        appBar: 1200,
        drawer: 1100,
      },
    })
  );

  theme.typography.body1 = {
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  };

  return theme;
}
  

export default getTheme;
export {default as makeHighlightStyle} from './makeHighlightStyle';
export {default as mdxComponents} from './mdxComponents';
