import React from 'react';
import {ConfidenceCheck} from 'components/organisms';

const ConfidenceCheckShortcode = ({children, ...rest}) => {
  if (children) {
    return <ConfidenceCheck prompt={children} />;
  }
  return <ConfidenceCheck {...rest} />;
};

export default ConfidenceCheckShortcode;
