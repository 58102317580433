import React, {memo} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Box,
  Checkbox,
} from '@material-ui/core';
import {Link} from 'gatsby-theme-material-ui';
import {CodeHighlighter, Image} from 'components/atoms';
import {makeStyles} from '@material-ui/core/styles';

const mdxComponents = {
  a: (() => {
    const useStyles = makeStyles(theme => ({
      root: {
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        textUnderlineOffset: '2px',
        textDecorationColor: theme.palette.text.secondary,
        color: 'inherit',
        '&:hover': {
          color: theme.palette.secondary.dark,
          textDecoration: 'underline',
          textDecorationStyle: 'dotted',
          textUnderlineOffset: '2px',
        },
      },
    }));
    const a = ({children, href}) => {
      const external = href.startsWith('http') || href.startsWith('mailto');
      const classes = useStyles;
      if (!external) {
        return (
          <Link to={href} className={classes.root}>
            {children}
          </Link>
        );
      }
      return (
        <a href={href} target="_blank" rel="noreferrer noopener">
          {children}
        </a>
      );
    };
    return memo(a);
  })(),

  p: (() => {
    const p = ({children}) => (
      <Typography component="div" variant="body1" gutterBottom>
        <Box component="p" mt={1} mb={3}>
          {children}
        </Box>
      </Typography>
    );
    return memo(p);
  })(),

  h1: (() => {
    const H1 = ({children}) => (
      <Typography component="h1" variant="h3" align="left" color="textPrimary" gutterBottom>
        <Box fontWeight={600} mt={4} mb={5}>
          {children}
        </Box>
      </Typography>
    );
    return memo(H1);
  })(),

  h2: (() => {
    const H2 = ({children}) => (
      <Typography component="h2" variant="h4" align="left" color="textPrimary" gutterBottom>
        <Box fontWeight={800} mt={7} mb={3}>
          {children}
        </Box>
      </Typography>
    );
    return memo(H2);
  })(),

  h3: (() => {
    const H3 = ({children}) => (
      <Typography component="h3" variant="h4" align="left" color="textSecondary" gutterBottom>
        <Box fontWeight={800} mt={7} mb={3}>
          {children}
        </Box>
      </Typography>
    );
    return memo(H3);
  })(),

  h4: (() => {
    const H4 = ({children}) => (
      <Typography component="h4" variant="h5" align="left" color="textPrimary" gutterBottom>
        <Box fontWeight={600} mt={6} mb={3}>
          {children}
        </Box>
      </Typography>
    );
    return memo(H4);
  })(),

  h5: (() => {
    const H5 = ({children}) => (
      <Typography component="h5" variant="h5" align="left" color="textSecondary" gutterBottom>
        <Box fontWeight={600} mt={6} mb={3}>
          {children}
        </Box>
      </Typography>
    );
    return memo(H5);
  })(),

  h6: (() => {
    const H6 = ({children}) => (
      <Typography variant="h6" align="left" color="textSecondary" gutterBottom>
        <Box fontWeight={800} mt={6} mb={3}>
          {children}
        </Box>
      </Typography>
    );
    return memo(H6);
  })(),

  blockquote: (() => {
    const Blockquote = ({...props}) => (
      <Paper style={{borderLeft: '4px solid grey', padding: 8}} {...props} />
    );
    return memo(Blockquote);
  })(),

  ul: (() => {
    const Ul = ({children, ...props}) => (
      <Box mb={3} mx={6}>
        <Typography {...props} gutterBottom component="ul">
          {children}
        </Typography>
      </Box>
    );
    return memo(Ul);
  })(),

  ol: (() => {
    const Ol = ({children, ...props}) => (
      <Box mb={4} mx={6}>
        <Typography {...props} gutterBottom component="ol">
          {children}
        </Typography>
      </Box>
    );
    return memo(Ol);
  })(),

  li: (() => {
    const Li = ({children, ...props}) => (
      <Typography {...props} gutterBottom component="li">
        {children}
      </Typography>
    );
    return memo(Li);
  })(),

  table: (() => {
    const TableObj = ({...props}) => (
      <Box mt={2} mb={5}>
        <Table {...props} />
      </Box>
    );
    return memo(TableObj);
  })(),

  tr: (() => {
    return memo(TableRow);
  })(),

  td: (() => {
    const Td = ({children}) => <TableCell align="left">{children}</TableCell>;
    return memo(Td);
  })(),

  tbody: (() => {
    return memo(TableBody);
  })(),

  th: (() => {
    const Th = ({children}) => (
      <TableCell align="left">
        <Box fontWeight={800}>{children}</Box>
      </TableCell>
    );
    return memo(Th);
  })(),

  thead: (() => {
    const TH = ({children}) => <TableHead>{children}</TableHead>;
    return memo(TH);
  })(),

  inlineCode: (() => {
    const useStyles = makeStyles(theme => ({
      root: {
        borderRadius: '4px',
        padding: '3px',
        backgroundColor: theme.palette.background.level2,
        fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
        fontSize: '0.9rem',
      },
    }));

    const IC = ({children}) => {
      const classes = useStyles();
      return <span className={classes.root}>{children}</span>;
    };
    return memo(IC);
  })(),

  code: (() => {
    const Code = ({...props}) => (
      <Box mt={2} mb={5}>
        <CodeHighlighter {...props} />
      </Box>
    );
    return memo(Code);
  })(),

  hr: (() => {
    const Line = () => (
      <Box my={6}>
        <Divider />
      </Box>
    );
    return memo(Line);
  })(),

  img: (() => {
    const Img = ({...props}) => (
      <Box mt={0} mb={0}>
        <Image {...props} />
      </Box>
    );
    return memo(Img);
  })(),

  input: (() => {
    const Input = props => {
      const {type} = props;
      if (type === 'checkbox') {
        return <Checkbox {...props} disabled={false} readOnly />;
      }
      return <input {...props} />;
    };
    return memo(Input);
  })(),

  wrapper: (() => {
    const Wrapper = props => (
      <Box>
        <div {...props} className="markdown-body" />
      </Box>
    );
    return memo(Wrapper);
  })(),
};

export default mdxComponents;
