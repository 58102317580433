import React from 'react';
import {TextBox} from 'components/atoms';

const Quote = ({children}) => <TextBox type="quote">{children}</TextBox>;
const Info = ({children}) => <TextBox type="info">{children}</TextBox>;
const Action = ({children}) => <TextBox type="action">{children}</TextBox>;
const Challenge = ({children}) => <TextBox type="challenge">{children}</TextBox>;
const Solution = ({children}) => <TextBox type="solution">{children}</TextBox>;
const Transcript = ({children}) => <TextBox type="transcript">{children}</TextBox>;

export {Quote, Info, Action, Challenge, Solution, Transcript};
