import React, {useState, useEffect} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {Paper} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MDXProvider} from '@mdx-js/react';
import getTheme, {mdxComponents} from 'theme';
import shortcodes from 'components/shortcodes';

export const useDarkMode = (pageContext) => {

  const getInitialMode = () => {
    if (typeof window !== 'undefined') {
      const savedMode = window.localStorage.getItem('themeMode');
      if (savedMode) {
        return savedMode;
      }
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return prefersDark ? 'dark' : 'light';
    } else {
      return 'light';
    }
  };
  
  const [themeMode, setTheme] = useState(getInitialMode);
  const [mountedComponent, setMountedComponent] = useState(false);

  const setMode = mode => {
    window.localStorage.setItem('themeMode', mode);
    setTheme(mode);
  };

  const themeToggler = () => (themeMode === 'light' ? setMode('dark') : setMode('light'));

  useEffect(() => {
    const mq = window.matchMedia('(prefers-color-scheme: dark)');
    let darkMode = mq.matches || pageContext?.frontmatter?.dark;
    darkMode ? setMode('dark') : setMode('light');
    setMountedComponent(true);
  }, []);

  return [themeMode, themeToggler, mountedComponent];
};

export default function WithLayout({component: Component, layout: Layout, pageContext, ...rest}) {
  const [themeMode, themeToggler] = useDarkMode(pageContext);
  const theme = getTheme(themeMode);

  return (
    <MDXProvider components={{...mdxComponents, ...shortcodes}}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Paper elevation={0}>
            <Layout
              themeMode={themeMode}
              themeToggler={themeToggler}
              pageContext={pageContext}
              {...rest}
            >
              <Component themeMode={themeMode} pageContext={pageContext} {...rest} />
            </Layout>
        </Paper>
      </ThemeProvider>
    </MDXProvider>
  );
}
