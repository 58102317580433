import React from 'react';
import {Video, Soundcloud, Form, Slides} from 'components/atoms';
import {ImageGrid, ImageTriplet} from 'components/molecules';

import FlashCard from './FlashCard';
import MultipleChoice from './MultipleChoice';
import TextResponse from './TextResponse';
import ConfidenceCheck from './ConfidenceCheck';
import SelfCheck from './SelfCheck';
import Exercise from './Exercise';
import {Quote, Info, Action, Challenge, Solution, Transcript} from './TextBox';

// These are pass-through components
// They ensure that the mdxType of the node will match a particular string,
// so that the logic in the wrapping components can work
const Prompt = ({children}) => <>{children}</>;
const Choice = ({children}) => <>{children}</>;
const Answer = ({children}) => <>{children}</>;

class ShortCodeError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(_error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error('Error in shortcode: ', error, errorInfo);
  }

  render() {
    const {hasError} = this.state;
    const {children} = this.props;

    if (hasError) {
      if (process.env.NODE_ENV === 'development') {
        return <div>Shortcode error, see console.</div>;
      }
      return <></>;
    }

    return children;
  }
}

const withErrorBoundary = Component => props => (
  <ShortCodeError>
    <Component {...props} />
  </ShortCodeError>
);

const shortcodes = Object.fromEntries(
  Object.entries({
    Action,
    Answer,
    Challenge,
    Choice,
    ConfidenceCheck,
    Exercise,
    FlashCard,
    Form,
    Info,
    ImageGrid,
    ImageTriplet,
    MultipleChoice,
    Prompt,
    Quote,
    SelfCheck,
    Slides,
    Solution,
    Soundcloud,
    TextResponse,
    Transcript,
    Video,
  }).map(([name, component]) => [name, withErrorBoundary(component)])
);

export default shortcodes;
