import React from 'react';
import {MultipleChoice} from 'components/organisms';
import PropTypes from 'prop-types';

// nodes before seeing an ordered list are the prompt
// the ordered list children are the choices
// the following nodes are the answer
const splitChildren = nodes => {
  const index = nodes.findIndex(node => node.props.originalType === 'ol');
  if (index === -1) {
    throw new Error("Didn't find an ordered list in the Multiple Choice page.");
  }
  const prompt = nodes.filter((_node, i) => i < index);
  const answer = nodes.filter((_node, i) => i > index);
  const choices = nodes[index].props.children;

  return [prompt, choices, answer];
};

const MultipleChoiceMDX = ({correct, children}) => {
  const [prompt, choices, answer] = splitChildren(children);
  // expect to write with 1-indexed lists in markdown
  const correctIndex = correct - 1;
  return <MultipleChoice {...{prompt, choices, answer, correctIndex}} />;
};

MultipleChoiceMDX.propTypes = {
  correct: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

const getChildrenIfExists = (nodes, type) => {
  const found = nodes.find(node => node.props.mdxType === type);
  return found && found.props.children;
};

const getParts = nodes => {
  const prompt = getChildrenIfExists(nodes, 'Prompt');
  const answer = getChildrenIfExists(nodes, 'Answer');

  const choices = nodes.filter(node => node.props.mdxType === 'Choice');
  const correctIndex = choices.findIndex(choiceNode => choiceNode.props.correct);

  return [prompt, answer, choices, correctIndex];
};

/*

There are two ways to use a MultipleChoice shortcode:

- with <Prompt>, <Choice>, and <Answer> children components
- with markdown children, that gets split into prompt, choices, and answer

The markdown syntax is shorter, but a little more magical.

*/
const MultipleChoiceShortcode = ({children, ...rest}) => {
  if (children) {
    const [prompt, answer, choices, correctIndex] = getParts(children);
    // need a prompt and at least one choice
    if (prompt && choices && choices.length > 0) {
      return (
        <MultipleChoice
          {...{
            prompt,
            choices,
            answer,
            correctIndex,
          }}
        />
      );
    }

    const {correct} = rest;
    return (
      <MultipleChoiceMDX correct={correct} {...rest}>
        {children}
      </MultipleChoiceMDX>
    );
  }

  return <MultipleChoice {...rest} />;
};

export default MultipleChoiceShortcode;
