import React from 'react';
import {TextResponse} from 'components/organisms';

const getChildrenIfExists = (nodes, type) => {
  const found = nodes.find(node => node.props.mdxType === type);
  return found && found.props.children;
};

const TextResponseShortcode = ({children, ...rest}) => {
  if (!children) {
    return <TextResponse {...rest} />;
  }
  const childArray = Array.isArray(children) ? children : [children];
  const prompt = getChildrenIfExists(childArray, 'Prompt');
  const answer = getChildrenIfExists(childArray, 'Answer');
  const selfcheck = childArray.find(node => node.props.mdxType === 'SelfCheck');
  return <TextResponse {...{prompt, answer, selfcheck}} />;
};

export default TextResponseShortcode;
